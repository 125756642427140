var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "TenantFormProductRegion-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
            scopedSlots: _vm._u([
              {
                key: "saveBeforeSlot",
                fn: function () {
                  return [
                    _c("v-button", {
                      attrs: { text: "返回" },
                      on: { click: _vm.returnClick },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "template",
                { slot: "headerSlot" },
                [
                  _c("v-button", {
                    attrs: { text: "返回" },
                    on: { click: _vm.goBack },
                  }),
                ],
                1
              ),
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "产品域名称",
                        rules: [
                          {
                            required: true,
                            message: "请选择产品域名称",
                            trigger: "change",
                          },
                        ],
                        prop: "id",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "查询产品域名称",
                              width: _vm.width,
                            },
                            on: { onChange: _vm.productRegionChange },
                            model: {
                              value: _vm.form.id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "id", $$v)
                              },
                              expression: "form.id",
                            },
                          },
                          "v-select2",
                          _vm.productRegionParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.infoVisible,
                          expression: "infoVisible",
                        },
                      ],
                    },
                    [
                      _c("el-form-item", { attrs: { label: "用户端APP" } }, [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.info.userAppName),
                          },
                        }),
                      ]),
                      _c("el-form-item", { attrs: { label: "服务端APP" } }, [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.info.serviceAppName),
                          },
                        }),
                      ]),
                      _c("el-form-item", { attrs: { label: "商家端APP" } }, [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.info.businessAppName),
                          },
                        }),
                      ]),
                      _c("el-form-item", { attrs: { label: "吉祥物昵称" } }, [
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.info.nickname) },
                        }),
                      ]),
                      _c("el-form-item", { attrs: { label: "吉祥物头像" } }, [
                        _c("img", {
                          staticClass: "img",
                          attrs: { src: _vm.info.headPicName },
                          on: {
                            click: function ($event) {
                              return _vm.viewPic(_vm.info.headPicName)
                            },
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }