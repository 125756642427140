<template>
  <div class="TenantFormProductRegion-wrapper">
    <form-panel
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
    >
      <col2-detail>
        <template slot="headerSlot">
          <v-button text="返回" @click="goBack"></v-button>
        </template>
        <col2-block>
          <el-form-item label="产品域名称" :rules="[{ required: true, message: '请选择产品域名称', trigger: 'change' }]" prop="id">
            <v-select2 v-model="form.id" placeholder="查询产品域名称" v-bind="productRegionParams" @onChange="productRegionChange" :width="width" />
          </el-form-item>
          <div v-show="infoVisible">
            <el-form-item label="用户端APP">
              <span v-text="info.userAppName" />
            </el-form-item>
            <el-form-item label="服务端APP">
              <span v-text="info.serviceAppName" />
            </el-form-item>
            <el-form-item label="商家端APP">
              <span v-text="info.businessAppName" />
            </el-form-item>
            <el-form-item label="吉祥物昵称">
              <span v-text="info.nickname" />
            </el-form-item>
            <el-form-item label="吉祥物头像">
              <img class="img" :src="info.headPicName" @click="viewPic(info.headPicName)" />
            </el-form-item>
          </div>
        </col2-block>
      </col2-detail>
      <template #saveBeforeSlot>
        <v-button text="返回"  @click="returnClick" />
      </template>
    </form-panel>
  </div>
</template>

<script>
import { Col2Detail, Col2Block } from 'components/bussiness'
import { queryProductRegionListURL, queryProductRegionDetailURL, attachProductRegionURL } from './api'

export default {
  name: 'TenantFormProductRegion',
  components: {
    Col2Detail,
    Col2Block
  },
  props: {
    tenantId: String,
    productRegionId: [Number, String]
  },
  data () {
    return {
      width: 182,
      productRegionParams: {
        searchUrl: queryProductRegionListURL
      },
      infoVisible: false,
      info: {},
      form: {
        id: undefined
      },
      submitConfig: {
        submitUrl: attachProductRegionURL,
        submitText: '下一步'
      }
    }
  },
  created () {
    this.resetInfo()
  },
  watch: {
    productRegionId (newValue) {
      this.form.id = newValue
    }
  },
  methods: {
    goBack () {
      this.$emit('goBack')
      this.$router.go(-1)
    },
    submitBefore (data) {
      return {
        id: data['id'],
        tenantId: this.tenantId
      }
    },
    submitSuccess () {
      this.$emit('submitSuccess')
    },
    returnClick () {
      this.$emit('returnClick')
    },
    resetInfo () {
      this.info = {
        userAppName: '',
        serviceAppName: '',
        businessAppName: '',
        nickname: '',
        headPicName: undefined
      }
    },
    async productRegionChange (productRegion) {
      if (productRegion && productRegion.id) {
        const params = {
          id: productRegion.id
        }
        const { status, data } = await this.$axios.get(queryProductRegionDetailURL, { params })
        if (status === 100) {
          Object.keys(this.info).forEach(key => {
            this.info[key] = data[key]
          })
          this.infoVisible = true
        }
      } else {
        this.infoVisible = false
        this.resetInfo()
      }
    },
    viewPic (src) {
      this.$previewBox([src])
    }
  }
}
</script>

<style lang="scss" scoped>
.TenantFormProductRegion-wrapper {
  height: 100%;
  .img {
    width: 140px;
    height: 140px;
    border-radius: 6px;
    cursor: pointer;
  }
}
</style>
