var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "TenantFormBase-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
            on: { update: _vm.update },
            scopedSlots: _vm._u([
              {
                key: "saveBeforeSlot",
                fn: function () {
                  return [
                    _c("v-button", {
                      attrs: { text: "返回" },
                      on: { click: _vm.goBack },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "template",
            { slot: "headerSlot" },
            [
              _c("v-button", {
                attrs: { text: "返回" },
                on: { click: _vm.goBack },
              }),
            ],
            1
          ),
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "公司名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入公司名称",
                            trigger: "blur",
                          },
                        ],
                        prop: "name",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入公司名称",
                          maxlength: 36,
                          disabled:
                            _vm.$route.query.tenantId && !_vm.updateTenantName,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "可建项目数",
                        rules: [
                          {
                            required: true,
                            message: "请输入可建项目数",
                            trigger: "blur",
                          },
                        ],
                        prop: "communityMaxNum",
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: {
                          placeholder: "请输入数字",
                          max: 9999,
                          controls: "",
                          controlsPosition: "right",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.communityMaxNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "communityMaxNum", $$v)
                          },
                          expression: "form.communityMaxNum",
                        },
                      }),
                      _c("label", { staticClass: "tips" }, [
                        _vm._v("该公司可自行关联的最大项目数量"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "虚拟公司",
                        rules: [
                          {
                            required: true,
                            message: "请选择虚拟公司",
                            trigger: "change",
                          },
                        ],
                        prop: "isVirtual",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.isVirtualOps, width: _vm.width },
                        model: {
                          value: _vm.form.isVirtual,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isVirtual", $$v)
                          },
                          expression: "form.isVirtual",
                        },
                      }),
                      _c("label", { staticClass: "tips" }, [
                        _vm._v("虚拟公司不计入数据统计"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属渠道",
                        rules: [
                          {
                            required: true,
                            message: "请选择所属渠道",
                            trigger: "change",
                          },
                        ],
                        prop: "tenantChannelId",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            staticClass: "select2-channel",
                            attrs: {
                              placeholder: "输入渠道名称",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.tenantChannelId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "tenantChannelId", $$v)
                              },
                              expression: "form.tenantChannelId",
                            },
                          },
                          "v-select2",
                          _vm.tenantChannelParams,
                          false
                        )
                      ),
                      _c("label", { staticClass: "tips" }, [
                        _vm._v("公司引入渠道商名称"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "公司标签",
                        rules: [
                          {
                            required: true,
                            message: "请选择公司标签",
                            trigger: "change",
                          },
                        ],
                        prop: "tenantLabelIds",
                      },
                    },
                    [
                      _c("checkbox-plus", {
                        attrs: {
                          type: "default",
                          options: _vm.labelList,
                          mode: "checkbox-plus",
                          value: _vm.form.tenantLabelIds,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "tenantLabelIds", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }