var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "TenantFormRoleCopy-wrapper" },
    [
      _c(
        "form-panel",
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "选择角色", rules: [{ required: true }] },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "查询角色名称",
                              handleData: _vm.roleHandleData,
                            },
                            on: { onChange: _vm.roleChange },
                            model: {
                              value: _vm.roleId,
                              callback: function ($$v) {
                                _vm.roleId = $$v
                              },
                              expression: "roleId",
                            },
                          },
                          "v-select2",
                          _vm.roleSelect2Params,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.roleMgrFormVisible
        ? _c(
            "role-mgr-form",
            _vm._b(
              {
                staticClass: "role-mgr-form",
                attrs: {
                  defaultOrgId: _vm.defaultOrgId,
                  defaultQueryId: _vm.defaultQueryIdCopy,
                  defaultRoleName: _vm.defaultRoleName,
                },
                on: { returnClick: _vm.returnClick },
              },
              "role-mgr-form",
              _vm.roleParams,
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }