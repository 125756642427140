<template>
  <div class="TenantFormRoleCopy-wrapper">
    <form-panel>
      <col2-detail>
        <col2-block>
          <el-form-item label="选择角色" :rules="[{ required: true }]">
            <v-select2 v-model="roleId" placeholder="查询角色名称" v-bind="roleSelect2Params" :handleData="roleHandleData" @onChange="roleChange" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
    <role-mgr-form class="role-mgr-form" v-if="roleMgrFormVisible" :defaultOrgId="defaultOrgId" :defaultQueryId="defaultQueryIdCopy" :defaultRoleName="defaultRoleName" v-bind="roleParams" @returnClick="returnClick" />
  </div>
</template>

<script>
import { getRolesURL } from './api'
import { Col2Detail, Col2Block } from 'components/bussiness'
import RoleMgrForm from 'views/common/roleMgr/RoleMgrForm'

export default {
  name: 'TenantFormRoleCopy',
  components: {
    Col2Detail,
    Col2Block,
    RoleMgrForm
  },
  props: {
    defaultOrgId: String,
    roleParams: Object
  },
  data () {
    return {
      roleId: undefined,
      roleSelect2Params: {
        searchUrl: getRolesURL,
        request: {
          text: 'roleName_',
          value: 'roleId_'
        },
        response: {
          text: 'alias',
          value: 'id'
        },
        subjoin: {
          roleType: '102'
        }
      },
      roleMgrFormVisible: false,
      defaultRoleName: undefined
    }
  },
  computed: {
    defaultQueryIdCopy () {
      return {
        copyId: this.roleId
      }
    }
  },
  methods: {
    roleChange (role) {
      if (role) {
        if (this.roleMgrFormVisible) {
          this.roleMgrFormVisible = false
        }
        this.$nextTick(() => {
          this.defaultRoleName = role.name
          this.roleMgrFormVisible = true
        }, 20)
      } else {
        this.roleMgrFormVisible = false
      }
    },
    returnClick () {
      this.$emit('returnClick')
    },
    roleHandleData (data) {
      if (data && data.length) {
        data.forEach(item => {
          item.alias = `${item.name}-${item.orgName}`
        })
      }
      return data
    }
  }
}
</script>

<style lang="scss" scoped>
.TenantFormRoleCopy-wrapper {
  height: 100%;
  .role-mgr-form {
    height: calc(100% - 85px);
    margin-top: -40px;
  }
}
</style>
