var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.progressActive > -1,
          expression: "progressActive > -1",
        },
      ],
      staticClass: "TenantForm-wrapper",
    },
    [
      _c(
        "div",
        { staticClass: "progress-panel" },
        [
          _c(
            "steps",
            { attrs: { active: _vm.progressActive, "align-center": "" } },
            _vm._l(_vm.processList, function (item, index) {
              return _c("step", { key: index, attrs: { title: item.title } })
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "content-panel" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.progressActive === 0,
                expression: "progressActive === 0",
              },
            ],
            staticClass: "base-info",
          },
          [
            _c("tenant-form-base", {
              on: {
                goBack: _vm.goBack,
                setInfo: _vm.setInfo,
                progressActiveChange: _vm.progressActiveChange,
                submitSuccess: _vm.baseInfoSubmitSuccess,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.progressActive === 1,
                expression: "progressActive === 1",
              },
            ],
            staticClass: "product-region-info",
          },
          [
            !_vm.info.productRegionId
              ? _c("checkbox-plus", {
                  staticClass: "checkbox-plus",
                  attrs: {
                    type: "radio",
                    options: _vm.productRegionOps,
                    value: _vm.productRegion,
                  },
                  on: {
                    "update:value": function ($event) {
                      _vm.productRegion = $event
                    },
                  },
                })
              : _vm._e(),
            _c("tenant-form-product-region", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.productRegion === "1",
                  expression: "productRegion === '1'",
                },
              ],
              attrs: {
                tenantId: _vm.info.tenantId,
                productRegionId: _vm.info.productRegionId,
              },
              on: {
                submitSuccess: _vm.productRegionSubmitSuccess,
                goBack: _vm.goBack,
                returnClick: _vm.progressActiveDecrease,
              },
            }),
            _c(
              "product-region-form",
              _vm._b(
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.productRegion === "2",
                      expression: "productRegion === '2'",
                    },
                  ],
                  attrs: { defaultRegionIds: _vm.defaultRegionIds },
                  on: {
                    goBack: _vm.goBack,
                    returnClick: _vm.progressActiveDecrease,
                  },
                },
                "product-region-form",
                _vm.productRegionParams,
                false
              )
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.progressActive === 2,
                expression: "progressActive === 2",
              },
            ],
            staticClass: "business-domain-info",
          },
          [
            !_vm.info.businessRegionId
              ? _c("checkbox-plus", {
                  staticClass: "checkbox-plus",
                  attrs: {
                    type: "radio",
                    options: _vm.businessDomainOps,
                    value: _vm.businessDomain,
                  },
                  on: {
                    "update:value": function ($event) {
                      _vm.businessDomain = $event
                    },
                  },
                })
              : _vm._e(),
            _c("tenant-form-business-domain", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.businessDomain === "1",
                  expression: "businessDomain === '1'",
                },
              ],
              attrs: {
                tenantId: _vm.info.tenantId,
                businessRegionId: _vm.info.businessRegionId,
              },
              on: {
                submitSuccess: _vm.businessDomianSubmitSuccess,
                goBack: _vm.goBack,
                returnClick: _vm.progressActiveDecrease,
              },
            }),
            _c(
              "business-domain-form",
              _vm._b(
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.businessDomain === "2",
                      expression: "businessDomain === '2'",
                    },
                  ],
                  attrs: { defaultRegionIds: _vm.defaultRegionIds },
                  on: {
                    goBack: _vm.goBack,
                    returnClick: _vm.progressActiveDecrease,
                  },
                },
                "business-domain-form",
                _vm.businessDomainParams,
                false
              )
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.progressActive === 3,
                expression: "progressActive === 3",
              },
            ],
            staticClass: "grant-application-platform-info",
          },
          [
            _c(
              "grant-application-platform-form",
              _vm._b(
                {
                  attrs: { defaultRegionId: _vm.defaultRegionId },
                  on: {
                    goBack: _vm.goBack,
                    returnClick: _vm.progressActiveDecrease,
                  },
                },
                "grant-application-platform-form",
                _vm.grantApplicationPlatformParams,
                false
              )
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.progressActive === 4,
                expression: "progressActive === 4",
              },
            ],
            staticClass: "role-info",
          },
          [
            _vm.info.roleId
              ? _c(
                  "role-mgr-form",
                  _vm._b(
                    {
                      attrs: { defaultQueryId: _vm.defaultQueryIdUpdate },
                      on: { returnClick: _vm.progressActiveDecrease },
                    },
                    "role-mgr-form",
                    _vm.roleParamsUpdate,
                    false
                  )
                )
              : _vm._e(),
            !_vm.info.roleId
              ? _c(
                  "div",
                  { staticClass: "role-info-create" },
                  [
                    _c("checkbox-plus", {
                      staticClass: "checkbox-plus",
                      attrs: {
                        type: "radio",
                        options: _vm.roleOps,
                        value: _vm.role,
                      },
                      on: {
                        "update:value": function ($event) {
                          _vm.role = $event
                        },
                      },
                    }),
                    _c("tenant-form-role-copy", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.role === "1",
                          expression: "role === '1'",
                        },
                      ],
                      attrs: {
                        defaultOrgId: _vm.defaultOrgId,
                        roleParams: _vm.roleParams,
                      },
                      on: { returnClick: _vm.progressActiveDecrease },
                    }),
                    _c(
                      "role-mgr-form",
                      _vm._b(
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.role === "2",
                              expression: "role === '2'",
                            },
                          ],
                          attrs: {
                            newAdd: true,
                            defaultOrgId: _vm.defaultOrgId,
                          },
                          on: {
                            goBack: _vm.goBack,
                            returnClick: _vm.progressActiveDecrease,
                          },
                        },
                        "role-mgr-form",
                        _vm.roleParams,
                        false
                      )
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.progressActive === 5,
                expression: "progressActive === 5",
              },
            ],
            staticClass: "account-info",
          },
          [
            _vm.info.adminId
              ? _c(
                  "member-info-form",
                  _vm._b(
                    {
                      ref: "memberInfoForm",
                      attrs: {
                        org: _vm.org,
                        defaultId: _vm.defaultId,
                        enterpriseUserInfoId: _vm.enterpriseUserInfoId,
                      },
                      on: { returnClick: _vm.progressActiveDecrease },
                    },
                    "member-info-form",
                    _vm.accountParamsUpdate,
                    false
                  )
                )
              : _vm._e(),
            !_vm.info.adminId
              ? _c(
                  "div",
                  { staticClass: "account-info-create" },
                  [
                    _c(
                      "member-info-form",
                      _vm._b(
                        {
                          ref: "memberInfoForm",
                          attrs: {
                            org: _vm.org,
                            defaultOrganizeId: _vm.defaultOrganizeId,
                            defaultRoleId: _vm.defaultRoleId,
                          },
                          on: { returnClick: _vm.progressActiveDecrease },
                        },
                        "member-info-form",
                        _vm.accountParams,
                        false
                      )
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }