var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "TenantFormBusinessDomain-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
            scopedSlots: _vm._u([
              {
                key: "saveBeforeSlot",
                fn: function () {
                  return [
                    _c("v-button", {
                      attrs: { text: "返回" },
                      on: { click: _vm.returnClick },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "template",
                { slot: "headerSlot" },
                [
                  _c("v-button", {
                    attrs: { text: "返回" },
                    on: { click: _vm.goBack },
                  }),
                ],
                1
              ),
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "业务域名称",
                        rules: [
                          {
                            required: true,
                            message: "请选择业务域名称",
                            trigger: "change",
                          },
                        ],
                        prop: "id",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "查询业务域名称",
                              width: _vm.width,
                            },
                            on: { onChange: _vm.businessDomainChange },
                            model: {
                              value: _vm.form.id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "id", $$v)
                              },
                              expression: "form.id",
                            },
                          },
                          "v-select2",
                          _vm.businessDomainParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.infoVisible,
                          expression: "infoVisible",
                        },
                      ],
                    },
                    [
                      _c(
                        "col2-block",
                        { attrs: { title: "管理后台" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "管理后台logo" } },
                            [
                              _c("img", {
                                staticClass: "img",
                                attrs: { src: _vm.info.businessLogo },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewPic(_vm.info.businessLogo)
                                  },
                                },
                              }),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "管理后台皮肤" } },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.info.businessSkin),
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "col2-block",
                        { attrs: { title: "H5模板" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "访客通行证" } },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.info.visitorMould),
                                },
                              }),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "H5应用授权" } },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.info.h5AuthMould),
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "col2-block",
                        { attrs: { title: "其他业务" } },
                        [
                          _c("el-form-item", { attrs: { label: "分享平台" } }, [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.info.sharingPlatAccountListStr
                                ),
                              },
                            }),
                          ]),
                          _c("el-form-item", { attrs: { label: "用户账户" } }, [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.info.openUserIntegral),
                              },
                            }),
                            _c("span", [_vm._v("，")]),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.info.openWalletRecharge
                                ),
                              },
                            }),
                          ]),
                          _c("el-form-item", { attrs: { label: "授权渠道" } }, [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.info.authChannelListStr
                                ),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }