<template>
  <div class="TenantFormBusinessDomain-wrapper">
    <form-panel
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
    >
      <col2-detail>
        <template slot="headerSlot">
          <v-button text="返回" @click="goBack"></v-button>
        </template>
        <col2-block>
          <el-form-item label="业务域名称" :rules="[{ required: true, message: '请选择业务域名称', trigger: 'change' }]" prop="id">
            <v-select2 v-model="form.id" placeholder="查询业务域名称" v-bind="businessDomainParams" @onChange="businessDomainChange" :width="width" />
          </el-form-item>
          <div v-show="infoVisible">
            <col2-block title="管理后台">
              <el-form-item label="管理后台logo">
                <img class="img" :src="info.businessLogo" @click="viewPic(info.businessLogo)" />
              </el-form-item>
              <el-form-item label="管理后台皮肤">
                <span v-text="info.businessSkin" />
              </el-form-item>
            </col2-block>
            <col2-block title="H5模板">
              <el-form-item label="访客通行证">
                <span v-text="info.visitorMould" />
              </el-form-item>
              <el-form-item label="H5应用授权">
                <span v-text="info.h5AuthMould" />
              </el-form-item>
            </col2-block>
            <col2-block title="其他业务">
              <el-form-item label="分享平台">
                <span v-text="info.sharingPlatAccountListStr" />
              </el-form-item>
              <el-form-item label="用户账户">
                <span v-text="info.openUserIntegral" />
                <span>，</span>
                <span v-text="info.openWalletRecharge" />
              </el-form-item>
              <el-form-item label="授权渠道">
                <span v-text="info.authChannelListStr" />
              </el-form-item>
            </col2-block>
          </div>
        </col2-block>
      </col2-detail>
      <template #saveBeforeSlot>
        <v-button text="返回"  @click="returnClick" />
      </template>
    </form-panel>
  </div>
</template>

<script>
import { Col2Detail, Col2Block } from 'components/bussiness'
import { queryBusinessDomainListURL, queryBusinessDomainDetailURL, attachBusinessDomainURL } from './api'

const openUserIntegralMap = {
  '0': '不开放用户积分',
  '1': '开放用户积分'
}

const openWalletRechargeMap = {
  '0': '不开放钱包充值',
  '1': '开放钱包充值'
}

export default {
  name: 'TenantFormBusinessDomain',
  components: {
    Col2Detail,
    Col2Block
  },
  props: {
    tenantId: String,
    businessRegionId: [Number, String]
  },
  data () {
    return {
      width: 182,
      businessDomainParams: {
        searchUrl: queryBusinessDomainListURL
      },
      infoVisible: false,
      info: {},
      form: {
        id: undefined
      },
      submitConfig: {
        submitUrl: attachBusinessDomainURL,
        submitText: '下一步'
      }
    }
  },
  created () {
    this.resetInfo()
  },
  watch: {
    businessRegionId (newValue) {
      this.form.id = newValue
    }
  },
  methods: {
    goBack () {
      this.$emit('goBack')
      this.$router.go(-1)
    },
    submitBefore (data) {
      return {
        id: data['id'],
        tenantId: this.tenantId
      }
    },
    submitSuccess () {
      this.$emit('submitSuccess')
    },
    returnClick () {
      this.$emit('returnClick')
    },
    resetInfo () {
      this.info = {
        businessLogo: undefined,
        businessSkin: undefined,
        visitorMould: '',
        h5AuthMould: '',
        sharingPlatAccountListStr: '',
        openUserIntegral: '',
        openWalletRecharge: '',
        authChannelListStr: ''
      }
    },
    async businessDomainChange (businessDomain) {
      if (businessDomain && businessDomain.id) {
        const url = `${queryBusinessDomainDetailURL}${businessDomain.id}`
        const { status, data } = await this.$axios.get(url)
        if (status === 100) {
          if (data) {
            this.form.id = data['id']
            this.info.businessLogo = data['businessLogo']
            const businessDetails = data && data['businessDetails']
            if (businessDetails && businessDetails.length) {
              const authChannelList = []
              const sharingPlatAccountList = []
              businessDetails.forEach(item => {
                if (item.mouldType === 'visitor_mould') {
                  this.info.visitorMould = item.mouldName
                } else if (item.mouldType === 'h5_auth_mould') {
                  this.info.h5AuthMould = item.mouldName
                } else if (item.mouldType === 'manage_skin_mould') {
                  this.info.businessSkin = item.mouldName
                } else if (item.mouldType === 'sharing_plat_account_weixin') {
                  if (item.mouldName) {
                    sharingPlatAccountList.push(item.mouldName)
                  }
                } else if (item.mouldType === 'sharing_plat_account_qq') {
                  if (item.mouldName) {
                    sharingPlatAccountList.push(item.mouldName)
                  }
                } else if (item.mouldType === 'open_user_integral') {
                  this.info.openUserIntegral = openUserIntegralMap[item.mouldCode]
                } else if (item.mouldType === 'open_wallet_recharge') {
                  this.info.openWalletRecharge = openWalletRechargeMap[item.mouldCode]
                } else if (item.mouldType === 'auth_channel_list') {
                  if (item.mouldName) {
                    authChannelList.push(item.mouldName)
                  }
                }
              })
              this.info.authChannelListStr = authChannelList && authChannelList.length ? authChannelList.join('，') : '-'
              this.info.sharingPlatAccountListStr = sharingPlatAccountList && sharingPlatAccountList.length ? sharingPlatAccountList.join('，') : '-'
            }
          }
          this.infoVisible = true
        }
      } else {
        this.infoVisible = false
        this.resetInfo()
      }
    },
    viewPic (src) {
      this.$previewBox([src])
    }
  }
}
</script>

<style lang="scss" scoped>
.TenantFormBusinessDomain-wrapper {
  height: 100%;
  .img {
    // width: 140px;
    // height: 140px;
    height: 44px;
    width: 225px;
    border-radius: 6px;
    cursor: pointer;
  }
}
</style>
