<template>
  <div class="TenantFormBase-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <template slot="headerSlot">
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <col2-detail>
        <col2-block>
          <el-form-item label="公司名称" :rules="[{ required: true, message: '请输入公司名称', trigger: 'blur' }]" prop="name">
            <v-input v-model="form.name" placeholder="请输入公司名称" :maxlength="36" :disabled="$route.query.tenantId && !updateTenantName" :width="width" />
          </el-form-item>
          <el-form-item label="可建项目数" :rules="[{ required: true, message: '请输入可建项目数', trigger: 'blur' }]" prop="communityMaxNum">
            <v-input-number v-model="form.communityMaxNum" placeholder="请输入数字" :max="9999" controls controlsPosition="right" :width="width" />
            <label class="tips">该公司可自行关联的最大项目数量</label>
          </el-form-item>
          <el-form-item label="虚拟公司" :rules="[{ required: true, message: '请选择虚拟公司', trigger: 'change' }]" prop="isVirtual">
            <v-select v-model="form.isVirtual" :options="isVirtualOps" :width="width" />
            <label class="tips">虚拟公司不计入数据统计</label>
          </el-form-item>
          <el-form-item label="所属渠道" :rules="[{ required: true, message: '请选择所属渠道', trigger: 'change' }]" prop="tenantChannelId">
            <v-select2 class="select2-channel" v-model="form.tenantChannelId" placeholder="输入渠道名称" v-bind="tenantChannelParams" :width="width" />
            <label class="tips">公司引入渠道商名称</label>
          </el-form-item>
           <el-form-item label="公司标签" :rules="[{ required: true, message: '请选择公司标签', trigger: 'change' }]" prop="tenantLabelIds">
              <checkbox-plus type="default" :options="labelList" mode="checkbox-plus" :value.sync="form.tenantLabelIds" />
          </el-form-item>
        </col2-block>
      </col2-detail>
      <template #saveBeforeSlot>
        <v-button text="返回"  @click="goBack" />
      </template>
    </form-panel>
  </div>
</template>

<script>
import { Col2Detail, Col2Block, CheckboxPlus } from 'components/bussiness'
import { setIsVirtualOps } from './map'
import { queryDetailURL, createURL, updateURL, queryChannelURL } from './api'

export default {
  name: 'TenantFormBase',
  components: {
    Col2Detail,
    Col2Block,
    CheckboxPlus
  },
  data () {
    return {
      width: 182,
      isVirtualOps: setIsVirtualOps(),
      tenantChannelParams: {
        searchUrl: queryChannelURL
      },
      form: {
        id: undefined,
        name: '',
        communityMaxNum: undefined,
        isVirtual: undefined,
        tenantChannelId: undefined,
        orgTag: '',
        tenantLabelIds: []
      },
      submitConfig: {
        queryUrl: '',
        submitUrl: '',
        submitMethod: '',
        submitText: '下一步'
      },
      labelList: [
        {
          label: '企业',
          value: '1'
        },
        {
          label: '家庭',
          value: '2'
        }
      ]
    }
  },
  mounted () {
    const { tenantId } = this.$route.query
    if (tenantId !== undefined) {
      this.submitConfig.submitUrl = updateURL
      this.submitConfig.submitMethod = 'PUT'
      this.submitConfig.queryUrl = `${queryDetailURL}${tenantId}`
      this.$refs.formPanel.getData()
    } else {
      this.submitConfig.submitUrl = createURL
      this.submitConfig.submitMethod = 'POST'
      // 设置下拉选择框的默认选项
      this.form.isVirtual = this.isVirtualOps[0].value
    }
  },
  computed: {
    updateTenantName () {
      return this.$store.getters.getPermission('updateTenantName')
    }
  },
  methods: {
    goBack () {
      this.$emit('goBack')
      this.$router.go(-1)
    },
    update (data) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
      const info = {
        tenantId: data['id'],
        tenantName: data['name'],
        productRegionId: data['productRegionId'] ? data['productRegionId'] : undefined,
        businessRegionId: data['businessRegionId'] ? data['businessRegionId'] : undefined,
        isSubjectCreated: data['isSubjectCreated'],
        roleId: data['roleId'],
        adminId: data['adminId'],
        enterpriseUserInfoId: data['enterpriseUserInfoId']
      }
      this.form.tenantLabelIds = data.orgTag.split(',')
      this.$emit('setInfo', info)
      const steps = [
        {
          key: 'productRegionId',
          step: 1
        },
        {
          key: 'businessRegionId',
          step: 2
        },
        {
          key: 'isSubjectCreated',
          step: 3
        },
        {
          key: 'roleId',
          step: 4
        },
        {
          key: 'adminId',
          step: 5
        }
      ]
      let step = 0
      for (let i = 0; i < steps.length; i++) {
        let _steps = steps[i]
        if (i === 2 && info[steps[3].key]) {
          continue
        }
        if (!info[_steps.key]) {
          step = _steps.step
          break
        }
      }
      this.$emit('progressActiveChange', step)
    },
    submitBefore (data) {
      data.orgTag = this.form.tenantLabelIds.join(',')
      delete data.tenantLabelIds
      return true
    },
    submitSuccess (data) {
      const tenantId = this.form.id ? this.form.id : data
      this.$emit('setInfo', {
        tenantId,
        tenantName: this.form.name
      })
      this.$emit('submitSuccess')
      this.form.id = tenantId
      this.submitConfig.submitUrl = updateURL
      this.submitConfig.submitMethod = 'PUT'
    }
  }
}
</script>

<style lang="scss" scoped>
.TenantFormBase-wrapper {
  height: 100%;
  .tips {
    margin-left: 20px;
  }
  .select2-channel {
    display: inline-block;
  }
}
</style>
