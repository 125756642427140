<template>
  <div class="TenantForm-wrapper" v-show="progressActive > -1">
    <div class="progress-panel">
      <steps :active="progressActive" align-center>
        <step v-for="(item, index) in processList" :key="index" :title="item.title" />
      </steps>
    </div>
    <div class="content-panel">

      <div class="base-info" v-show="progressActive === 0">
        <tenant-form-base @goBack="goBack" @setInfo="setInfo" @progressActiveChange="progressActiveChange" @submitSuccess="baseInfoSubmitSuccess"/>
      </div>

      <div class="product-region-info" v-show="progressActive === 1">
        <checkbox-plus class="checkbox-plus" v-if="!info.productRegionId" type="radio" :options="productRegionOps" :value.sync="productRegion" />
        <tenant-form-product-region v-show="productRegion === '1'" :tenantId="info.tenantId" :productRegionId="info.productRegionId" @submitSuccess="productRegionSubmitSuccess" @goBack="goBack" @returnClick="progressActiveDecrease" />
        <product-region-form v-show="productRegion === '2'" :defaultRegionIds="defaultRegionIds" v-bind="productRegionParams" @goBack="goBack" @returnClick="progressActiveDecrease" />
      </div>

      <div class="business-domain-info" v-show="progressActive === 2">
        <checkbox-plus class="checkbox-plus" v-if="!info.businessRegionId" type="radio" :options="businessDomainOps" :value.sync="businessDomain" />
        <tenant-form-business-domain v-show="businessDomain === '1'" :tenantId="info.tenantId" :businessRegionId="info.businessRegionId" @submitSuccess="businessDomianSubmitSuccess" @goBack="goBack" @returnClick="progressActiveDecrease" />
        <business-domain-form v-show="businessDomain === '2'" :defaultRegionIds="defaultRegionIds" v-bind="businessDomainParams" @goBack="goBack" @returnClick="progressActiveDecrease" />
      </div>

      <div class="grant-application-platform-info" v-show="progressActive === 3">
        <grant-application-platform-form :defaultRegionId="defaultRegionId" v-bind="grantApplicationPlatformParams" @goBack="goBack" @returnClick="progressActiveDecrease" />
      </div>

      <div class="role-info" v-show="progressActive === 4">
        <role-mgr-form v-if="info.roleId" :defaultQueryId="defaultQueryIdUpdate" v-bind="roleParamsUpdate" @returnClick="progressActiveDecrease" />
        <div class="role-info-create" v-if="!info.roleId">
          <checkbox-plus class="checkbox-plus" type="radio" :options="roleOps" :value.sync="role" />
          <tenant-form-role-copy v-show="role === '1'" :defaultOrgId="defaultOrgId" :roleParams="roleParams" @returnClick="progressActiveDecrease"/>
          <role-mgr-form v-show="role === '2'" :newAdd="true" :defaultOrgId="defaultOrgId" v-bind="roleParams" @goBack="goBack" @returnClick="progressActiveDecrease" />
        </div>
      </div>

      <div class="account-info" v-show="progressActive === 5">
        <!-- <account-mgr-form v-if="info.adminId" :defaultId="defaultId" v-bind="accountParamsUpdate" @returnClick="progressActiveDecrease" />
        <div class="account-info-create" v-if="!info.adminId">
          <account-mgr-form :defaultOrganizeId="defaultOrganizeId" :defaultRoleId="defaultRoleId" v-bind="accountParams" @returnClick="progressActiveDecrease" /> -->
        <member-info-form ref="memberInfoForm" v-if="info.adminId" :org="org"
          :defaultId="defaultId" :enterpriseUserInfoId="enterpriseUserInfoId"
          v-bind="accountParamsUpdate" @returnClick="progressActiveDecrease"
        />
        <div class="account-info-create" v-if="!info.adminId">
          <member-info-form ref="memberInfoForm" :org="org"
            :defaultOrganizeId="defaultOrganizeId"
            :defaultRoleId="defaultRoleId"
            v-bind="accountParams"
            @returnClick="progressActiveDecrease"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { queryDetailURL, getTenantCommonRoleURL } from './api'
import { Steps, Step } from 'element-ui'
import { CheckboxPlus } from 'components/bussiness'
import TenantFormBase from './TenantFormBase'
import TenantFormProductRegion from './TenantFormProductRegion'
import ProductRegionForm from 'views/common/productRegion/ProductRegionForm'
import TenantFormBusinessDomain from './TenantFormBusinessDomain'
import BusinessDomainForm from 'views/common/businessDomain/BusinessDomainForm'
import GrantApplicationPlatformForm from 'views/application/applicationLibrary/applicationLibraryPlatform/GrantApplicationPlatformForm'
import TenantFormRoleCopy from './TenantFormRoleCopy'
import RoleMgrForm from 'views/common/roleMgr/RoleMgrForm'
// import AccountMgrForm from 'views/common/accountMgr/AccountMgrForm'
import MemberInfoForm from 'views/common/manageOrganize/MemberInfoForm'

export default {
  name: 'TenantForm',
  components: {
    Steps,
    Step,
    CheckboxPlus,
    TenantFormBase,
    TenantFormProductRegion,
    ProductRegionForm,
    TenantFormBusinessDomain,
    BusinessDomainForm,
    GrantApplicationPlatformForm,
    TenantFormRoleCopy,
    RoleMgrForm,
    // AccountMgrForm,
    MemberInfoForm
  },
  data () {
    return {
      progressActive: -1,
      processList: [
        {
          title: '基本信息'
        },
        {
          title: '产品域配置'
        },
        {
          title: '业务域配置'
        },
        {
          title: '应用配置'
        },
        {
          title: '角色配置'
        },
        {
          title: '账号配置'
        }
      ],
      info: {
        tenantId: undefined,
        tenantName: undefined,
        productRegionId: undefined,
        businessRegionId: undefined,
        isSubjectCreated: undefined,
        roleId: undefined,
        adminId: undefined
      },
      productRegion: '1',
      productRegionOps: [
        {
          label: '选择已有产品域',
          value: '1'
        },
        {
          label: '新建产品域',
          value: '2'
        }
      ],
      businessDomain: '1',
      businessDomainOps: [
        {
          label: '选择已有业务域',
          value: '1'
        },
        {
          label: '新建业务域',
          value: '2'
        }
      ],
      role: '1',
      roleOps: [
        {
          label: '从已有角色复制',
          value: '1'
        },
        {
          label: '新建角色',
          value: '2'
        }
      ],
      productRegionParams: {
        setBreadcrumb: false,
        submitText: '下一步',
        showReturn: true,
        submitSuccess: this.productRegionSubmitSuccess
      },
      businessDomainParams: {
        setBreadcrumb: false,
        title: '',
        submitText: '下一步',
        showReturn: true,
        submitSuccess: this.businessDomianSubmitSuccess
      },
      grantApplicationPlatformParams: {
        setBreadcrumb: false,
        title: '',
        submitText: '下一步',
        showReturn: true,
        submitSuccess: this.grantApplicationPlatformSubmitSuccess
      },
      roleParamsUpdate: {
        setBreadcrumb: false,
        submitText: '下一步',
        showReturn: true,
        submitSuccess: this.roleSubmitSuccess
      },
      roleParams: {
        setBreadcrumb: false,
        submitText: '下一步',
        showReturn: true,
        submitSuccess: this.roleSubmitSuccess,
        defaultRoleType: '102',
        defaultIsCommon: 1,
        commonType: 1,
        isSystem: 1
      },
      accountParamsUpdate: {
        setBreadcrumb: false,
        showReturn: true
      },
      accountParams: {
        setBreadcrumb: false,
        showReturn: true,
        defaultRoleType: '102',
        isSystemAdded: 1
      },
      // MemberInfoList 页面树节点 id 和 父id
      org: {
        orgParentId: '',
        orgId: '',
        orgType: 2
      }
    }
  },
  computed: {
    defaultRegionIds () {
      return [{
        id: this.info.tenantId,
        text: this.info.tenantName
      }]
    },
    defaultRegionId () {
      return this.info.tenantId
    },
    defaultOrgId () {
      return this.info.tenantId
    },
    defaultId () {
      return this.info.adminId
    },
    enterpriseUserInfoId () {
      return this.info.enterpriseUserInfoId
    },
    defaultOrganizeId () {
      return this.info.tenantId
    },
    defaultRoleId () {
      return this.info.roleId
    },
    defaultQueryIdUpdate () {
      return {
        id: this.info.roleId
      }
    }
  },
  created () {
    const { tenantId, orgParentId, orgId } = this.$route.query
    this.org.orgId = orgId ? String(orgId) : ''
    this.org.orgParentId = orgParentId ? String(orgParentId) : ''
    // this.org.orgType = 2
    if (tenantId !== undefined) {
      this.$setBreadcrumb('编辑')
    } else {
      this.$setBreadcrumb('新增')
      this.progressActive = 0
    }
  },
  methods: {
    goBack () {
      sessionStorage.setItem('orgTree', JSON.stringify({
        orgId: this.org.orgId,
        orgParentId: this.org.orgParentId,
        orgType: 2 // 写死，表明是租户
      }))
    },
    setInfo (info) {
      Object.keys(info).forEach(key => {
        this.info[key] = info[key]
      })
    },
    progressActiveChange (progressActive) {
      this.progressActive = progressActive
    },
    progressActiveIncrease () {
      this.progressActive += 1
    },
    progressActiveDecrease () {
      this.progressActive -= 1
    },
    baseInfoSubmitSuccess () {
      this.progressActiveIncrease()
    },
    async productRegionSubmitSuccess () {
      const tenantId = this.info.tenantId
      const url = `${queryDetailURL}${tenantId}`
      const { status, data } = await this.$axios.get(url)
      if (status === 100) {
        if (data) {
          const info = {
            productRegionId: data.productRegionId
          }
          this.setInfo(info)
          this.productRegion = '1'
          this.progressActiveIncrease()
        }
      }
    },
    async businessDomianSubmitSuccess () {
      const tenantId = this.info.tenantId
      const url = `${queryDetailURL}${tenantId}`
      const { status, data } = await this.$axios.get(url)
      if (status === 100) {
        if (data) {
          const info = {
            businessRegionId: data.businessRegionId
          }
          this.setInfo(info)
          this.businessDomain = '1'
          this.progressActiveIncrease()
        }
      }
    },
    grantApplicationPlatformSubmitSuccess () {
      this.progressActiveIncrease()
    },
    async roleSubmitSuccess () {
      const params = {
        tenantId: this.info.tenantId
      }
      const { status, data } = await this.$axios.get(getTenantCommonRoleURL, { params })
      if (status === 100) {
        if (data) {
          if (this.$refs.memberInfoForm) {
            this.$refs.memberInfoForm.getTreeList()
          }
          const info = {
            roleId: data.id
          }
          this.setInfo(info)
          this.progressActiveIncrease()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.TenantForm-wrapper {
  .progress-panel {
    background-color: #FFF;
    padding-bottom: 20px;
  }
  .content-panel {
    height: calc(100% - 143px);
    .base-info, .product-region-info, .business-domain-info, .grant-application-platform-info, .role-info, .account-info {
      height: 100%;
      .checkbox-plus {
        background-color: #FFF;
        padding: 15px 0 15px 188px;
      }
    }
    .role-info {
      .role-info-create {
        height: 100%;
      }
    }
    .account-info {
      overflow: auto;
      .account-info-create {
        height: 100%;
      }
    }
  }
}
</style>
