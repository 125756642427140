import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '正常',
    value: 1
  },
  {
    text: '未完善',
    value: 0
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

// 虚拟租户
const isVirtual = [
  {
    text: '否',
    value: 0
  },
  {
    text: '是',
    value: 1
  }
]

const {
  map: isVirtualMap,
  setOps: setIsVirtualOps
} = mapHelper.setMap(isVirtual)

export {
  statusMap,
  setStatusOps,
  isVirtualMap,
  setIsVirtualOps
}
